import { navigate, PageProps } from "gatsby"
import React, { ReactElement } from "react"
import MediaQuery from "react-responsive"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  Layout,
  SEO,
  ParkingReservationInfoTable,
  ParkingReservationForm,
} from "../components"
import * as config from "../config"
import AuthGuard from "../middleware/AuthGuard"
import * as styles from "./parking-reservation.module.scss"

interface PageContext {
  parking: Parking
}

const ParkingReservationPage = (
  props: PageProps<void, PageContext>
): ReactElement => {
  const currentParking = props.pageContext.parking
  return (
    <AuthGuard>
      <Layout>
        <SEO
          title={currentParking.displayName}
          description={
            currentParking &&
            `${currentParking.displayName}（${
              currentParking.fullAddress
            }）の予約情報です。車室数は${
              currentParking.availability?.totalSpaceCount ?? "-"
            }台です。`
          }
          lang="ja"
        />
        <div className={styles.mainContainer}>
          <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
            <button
              className={styles.backToTopButton}
              onClick={() => navigate(`/${currentParking.name}` ?? "/")}
            >
              詳細画面に戻る
            </button>
          </MediaQuery>
          <div className={styles.reservationContainer}>
            <section className={styles.alertSection}>
              <h2>【ご注意】</h2>
              <p>
                ・予約開始から入庫までの時間も駐車料金が発生します（予約料金）。予約料金には最大料金が適用されませんのでご注意ください。
              </p>
              <p className={styles.noticeText}>
                ・「コインパ予約」は、駐車場の車室を完全に確保するものではございません。他のお客様が間違って入庫してしまう場合もございますのでご了承ください。この場合、予約料金は間違って入庫されたお客様にご請求します。
              </p>
            </section>
            <ParkingReservationInfoTable parking={currentParking} />
            <ParkingReservationForm parking={currentParking} />
          </div>
        </div>
      </Layout>
    </AuthGuard>
  )
}

export default ParkingReservationPage
